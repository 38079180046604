var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-data-table', {
    staticClass: "table-section text-no-wrap",
    attrs: {
      "headers": _vm.columns,
      "items": _vm.resources,
      "options": _vm.options,
      "server-items-length": _vm.resourcesTotal,
      "loading": _vm.loading,
      "header-props": _vm.headerprops
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" #" + _vm._s(item.id) + " ")];
      }
    }, {
      key: "item.distributor",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.distributor.name))])];
      }
    }, {
      key: "item.batch_name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.batch.name.en))])];
      }
    }, {
      key: "item.type",
      fn: function fn(_ref4) {
        var _item$batch$type;
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s((_item$batch$type = item.batch.type) !== null && _item$batch$type !== void 0 ? _item$batch$type : '-'))])];
      }
    }, {
      key: "item.user",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.user ? item.user.email : '-'))])];
      }
    }, {
      key: "item.redeemed_at",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.redeemed_at ? _vm.dayjs(item.redeemed_at).format('YYYY-MM-DD HH:mm:ss') : '-'))])];
      }
    }, {
      key: "item.expired_at",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.expired_at ? _vm.dayjs(item.expired_at).format('YYYY-MM-DD HH:mm:ss') : '-'))])];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }