var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "max-width": "800px",
      "persistent": "",
      "full-height": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mb-1 me-3",
          attrs: {
            "color": "primary",
            "outlined": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "me-1",
          attrs: {
            "size": "17"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _c('span', [_vm._v("Generate")])], 1)];
      }
    }]),
    model: {
      value: _vm.isDialogVisible,
      callback: function callback($$v) {
        _vm.isDialogVisible = $$v;
      },
      expression: "isDialogVisible"
    }
  }, [_c('v-card', {
    attrs: {
      "title": "Import Doctor List"
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('translation-locales')], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.distributorOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "Select Distributor",
      "outlined": ""
    },
    model: {
      value: _vm.form.distributor_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "distributor_id", $$v);
      },
      expression: "form.distributor_id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('translatable-input', {
    attrs: {
      "label": "Name"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.typeOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "Type",
      "outlined": ""
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Code Prefix (e.g. MIX)",
      "outlined": ""
    },
    model: {
      value: _vm.form.prefix,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "prefix", $$v);
      },
      expression: "form.prefix"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Code Length with prefix",
      "outlined": ""
    },
    model: {
      value: _vm.form.length,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "length", $$v);
      },
      expression: "form.length"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Points",
      "outlined": ""
    },
    model: {
      value: _vm.form.points,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "points", $$v);
      },
      expression: "form.points"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Quantity",
      "outlined": ""
    },
    model: {
      value: _vm.form.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "quantity", $$v);
      },
      expression: "form.quantity"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Validity Period",
      "suffix": "days",
      "hint": "T+".concat(_vm.form.validity_period, " days for the expiry date of reward point when user redeems codes"),
      "persistent-hint": "",
      "outlined": ""
    },
    model: {
      value: _vm.form.validity_period,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "validity_period", $$v);
      },
      expression: "form.validity_period"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "readonly": "",
            "label": "Expiry Date",
            "persistent-hint": "",
            "outlined": ""
          },
          model: {
            value: _vm.form.expired_at,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "expired_at", $$v);
            },
            expression: "form.expired_at"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.isDatePickerVisible,
      callback: function callback($$v) {
        _vm.isDatePickerVisible = $$v;
      },
      expression: "isDatePickerVisible"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "color": "primary"
    },
    on: {
      "input": function input($event) {
        _vm.isDatePickerVisible = false;
      }
    },
    model: {
      value: _vm.form.expired_at,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "expired_at", $$v);
      },
      expression: "form.expired_at"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "Remarks",
      "outlined": ""
    },
    model: {
      value: _vm.form.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "error",
      "outlined": "",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v(" Close ")]), _c('v-btn', {
    attrs: {
      "color": "success",
      "disabled": _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(" Generate ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }