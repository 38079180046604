import axios from '@axiosV2'

const index = params =>
  axios.get('/admin/reward-points/vouchers', {
    params,
  })
// const show = id => axios.get(`/admin/distributors/${id}`)
// const store = data => axios.post('/admin/distributors', data)
// const update = (id, data) => axios.put(`/admin/distributors/${id}`, data)
// const destroy = id => axios.delete(`/admin/distributors/${id}`)

export default {
  index,
  // store,
  // show,
  // update,
  // destroy,
}
