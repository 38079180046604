<template>
  <div>
    <v-dialog
      v-model="isDialogVisible"
      max-width="800px"
      persistent
      full-height
    >
      <!-- Dialog Activator -->
      <template #activator="{ on, attrs }">
        <v-btn
          color="primary"
          outlined
          class="mb-1 me-3"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            size="17"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Generate</span>
        </v-btn>
      </template>

      <!-- Dialog Content -->
      <v-card title="Import Doctor List">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <translation-locales />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="form.distributor_id"
                :items="distributorOptions"
                item-text="title"
                item-value="value"
                label="Select Distributor"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <translatable-input
                v-model="form.name"
                label="Name"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="form.type"
                :items="typeOptions"
                item-text="title"
                item-value="value"
                label="Type"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.prefix"
                label="Code Prefix (e.g. MIX)"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.length"
                label="Code Length with prefix"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.points"
                label="Points"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.quantity"
                label="Quantity"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.validity_period"
                label="Validity Period"
                suffix="days"
                :hint="`T+${form.validity_period} days for the expiry date of reward point when user redeems codes`"
                persistent-hint
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="isDatePickerVisible"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.expired_at"
                    readonly
                    v-bind="attrs"
                    label="Expiry Date"
                    persistent-hint
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.expired_at"
                  color="primary"
                  @input="isDatePickerVisible = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="form.remarks"
                label="Remarks"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            outlined
            :disabled="loading"
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            :disabled="loading"
            :loading="loading"
            @click="handleSubmit"
          >
            Generate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { voucherBatchService } from '../services'
import { TranslatableInput, TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import { mdiPlus } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import moment from 'moment'
import { useDistributor } from '@/composables'

export default {
  components: { TranslationLocales, TranslatableInput },
  props: {
    distributorId: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const { distributorOptions } = useDistributor()

    const isDialogVisible = ref(false)
    const isDatePickerVisible = ref(false)

    const loading = ref(false)

    const initialFormData = () => ({
      distributor_id: null,
      name: useTranslatable(null),
      type: null,
      prefix: null,
      length: 20,
      points: 100,
      quantity: 10,
      expired_at: moment().add(1, 'months').format('YYYY-MM-DD'),
      validity_period: 365,
    })

    const form = ref({ ...initialFormData() })

    const typeOptions = [
      {
        title: 'Free Trial',
        value: 'free_trial',
      },
      {
        title: 'Promotion',
        value: 'promotion',
      },
      {
        title: 'Employee Reward',
        value: 'employee_reward',
      },
    ]

    const resetForm = () => {
      form.value = initialFormData()
    }

    const closeDialog = () => {
      resetForm()

      isDialogVisible.value = false
    }

    const handleSubmit = async () => {
      loading.value = true

      try {
        await voucherBatchService.store(form.value)

        resetForm()

        emit('generated')

        isDialogVisible.value = false
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    watch(
      () => props.distributorId,
      () => {
        form.value.distributor_id = props.distributorId
      },
    )

    return {
      isDialogVisible,
      isDatePickerVisible,

      loading,

      form,

      distributorOptions,
      typeOptions,

      closeDialog,
      handleSubmit,

      icons: {
        mdiPlus,
      },
    }
  },
}
</script>
