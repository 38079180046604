<template>
  <div>
    <v-card class="filter-section">
      <v-row class="">
        <v-col cols="6">
          <v-card-title class="font-weight-bold "> {{$t('common.list.filters')}} </v-card-title>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <v-btn icon @click="showFilter = !showFilter">
            <v-icon>{{ showFilter ? icons.mdiMenuUp : icons.mdiMenuDown }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row class="px-2 ma-0" v-show="showFilter">
          <v-col cols="12" sm="3">
            <v-autocomplete
              v-model="filters.distributor_id"
              label="Distributor"
              :items="distributorOptions"
              item-text="title"
              item-value="value"
              outlined
              clearable
              @change="onFilterUpdated({
                distributor_id: $event,
              })"
            />
          </v-col>

          <v-col cols="12" sm="3">
            <v-select
              v-model="filters.redeemed"
              placeholder="Status"
              :items="statusOptions"
              item-text="title"
              item-value="value"
              single-line
              outlined
              clearable hide-details
              @change="onFilterUpdated({
                redeemed: $event,
              })" />
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card>

    <v-card class="mt-5">
      <v-card-text class="d-flex">
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="fetchData"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>

        <v-spacer />

        <generate-dialog
          :distributor-id="filters.distributorId"
          @generated="fetchData"
        />

        <v-btn depressed color="success" @click="exportVoucher">
          <v-icon class="prefix-icon">{{ icons.mdiDatabaseExportOutline }}</v-icon>
          {{$t('common.list.export')}}
        </v-btn>
      </v-card-text>

      <!-- Table -->
      <voucher-table
        ref="tableRef"
        :loading="loading"
        :resources="vouchers"
        :resources-total="vouchersTotal"
        @resources-updated="fetchData"
        @options-updated="onOptionsUpdated"
      />
    </v-card>
  </div>
</template>

<script>
import { mdiDatabaseExportOutline, mdiDeleteOutline, mdiDotsVertical, mdiMenuDown, mdiMenuUp, mdiPencilOutline, mdiRefresh } from '@mdi/js'

import { t } from '@/plugins/i18n'
import dayjs from 'dayjs'

import { useOldDataFetch, useOldTableList } from '@/composables'
import { ref } from '@vue/composition-api'
import VoucherTable from '../components/VoucherTable.vue'
import GenerateDialog from '@/modules/rewardPoint/voucher/components/GenerateDialog'
import { voucherService, VoucherExportService } from '../services'
import { useDistributor } from '@/composables'

export default {
  components: {
    VoucherTable,
    GenerateDialog,
  },

  setup() {
    const showFilter = ref(true)

    const { parseParam } = useOldDataFetch()
    const { loading, tableRef, mergeOptionToParams } = useOldTableList()

    const vouchers = ref([])
    const vouchersTotal = ref(0)

    const filters = ref({
      distributor_id: null,
      batch_id: null,
      redeemed: null,
    })

    const params = ref({
      ...filters.value,
    })

    const { distributorOptions } = useDistributor()

    const statusOptions = ref([
      {
        title: 'Redeemed',
        value: 1,
      },
      {
        title: 'Not Yet Redeemed',
        value: 0,
      },
    ])

    const fetchData = async () => {
      loading.value = true

      try {
        const { records, pagination } = await voucherService.index(parseParam(params.value))

        vouchers.value = records
        vouchersTotal.value = pagination.total
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const exportVoucher = async () => {
      await VoucherExportService.store(parseParam(filters.value))
    }

    const onFilterUpdated = value => {
      console.log(value)

      params.value = {
        ...params.value,
        ...value,
      }

      console.log(params.value)

      // Reset page
      params.value.page = 1
      tableRef.value.resetPage(1)

      fetchData()
    }

    const onOptionsUpdated = options => {
      params.value = mergeOptionToParams(options, params.value)

      fetchData()
    }

    return {
      t,
      dayjs,

      loading,

      // Filters
      showFilter,
      filters,
      statusOptions,
      distributorOptions,

      // Refs
      tableRef,

      vouchers,
      vouchersTotal,

      // functions
      fetchData,
      exportVoucher,
      onFilterUpdated,
      onOptionsUpdated,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiRefresh,
        mdiDatabaseExportOutline,
        mdiMenuUp,
        mdiMenuDown,
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
