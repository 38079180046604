import axios from '@axiosV2'

const index = params =>
  axios.get('/admin/reward-points/voucher-batches', {
    params,
  })

const store = data => axios.post('/admin/reward-points/voucher-batches', data)

export default {
  index,
  store,
}
