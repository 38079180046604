<template>
  <v-card>
    <v-data-table
      :headers="columns"
      :items="resources"
      :options.sync="options"
      :server-items-length="resourcesTotal"
      :loading="loading"
      class="table-section text-no-wrap"
      :header-props="headerprops"
    >
        <!-- ID -->
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>
        <!-- distributor name -->
        <template #[`item.distributor`]="{ item }">
          <span class="text-no-wrap">{{ item.distributor.name }}</span>
        </template>

        <!-- batch name -->
        <template #[`item.batch_name`]="{ item }">
          <span class="text-no-wrap">{{ item.batch.name.en}}</span>
        </template>

        <!-- batch type -->
        <template #[`item.type`]="{ item }">
          <span class="text-no-wrap">{{ item.batch.type ?? '-' }}</span>
        </template>

        <!-- Redeemed By-->
        <template #[`item.user`]="{ item }">
          <span class="text-no-wrap">{{ item.user ? item.user.email : '-' }}</span>
        </template>

        <!-- Redeemed At-->
        <template #[`item.redeemed_at`]="{ item }">
          <span class="text-no-wrap">{{ item.redeemed_at ? dayjs(item.redeemed_at).format('YYYY-MM-DD HH:mm:ss') : '-'}}</span>
        </template>

        <!-- expiry date -->
        <template #[`item.expired_at`]="{ item }">
          <span class="text-no-wrap">{{ item.expired_at ? dayjs(item.expired_at).format('YYYY-MM-DD HH:mm:ss') : '-'}}</span>
        </template>
      </v-data-table>
  </v-card>
</template>

<script>
import { useOldDataTable } from '@/composables'
import { t } from '@/plugins/i18n'
import { mdiDatabaseExportOutline, mdiDeleteOutline, mdiDotsVertical, mdiMenuDown, mdiMenuUp, mdiPencilOutline, mdiPlus, mdiRefresh, mdiSync } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    resources: {
      type: Array,
      default: () => [],
    },
    resourcesTotal: {
      type: Number,
      default: 0,
    },
  },
  components: {},
  setup(props, { emit }) {
    const { isPageManualChange, defaultOptions, headerprops } = useOldDataTable()

    // add custom options
    const options = ref({
      ...defaultOptions,
      sortBy: ['id'],
      sortDesc: [true],
    })

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'Distributor', value: 'distributor' },
      { text: 'Batch Name', value: 'batch_name' },
      { text: 'Type', value: 'type' },
      { text: 'Code', value: 'code' },
      { text: 'Points', value: 'points' },
      { text: 'Expiry Date', value: 'expired_at' },
      { text: 'Redeemed By', value: 'user' },
      { text: 'Redeemed At', value: 'redeemed_at' },
    ]

    const formatDatetime = datetime => {
      return dayjs(datetime).utc().local().format('YYYY-MM-DD')
    }

    const onResourcesUpdated = () => {
      emit('resources-updated')
    }

    const resetPage = (page = 1) => {
      isPageManualChange.value = true

      options.value.page = page

      setTimeout(() => {
        isPageManualChange.value = false
      }, 500)
    }

    watch(
      () => options.value,
      newValue => {
        if (!isPageManualChange.value) {
          emit('options-updated', newValue)
        }
      },
    )

    return {
      t,
      dayjs,

      // Table config
      columns,
      options,
      headerprops,

      formatDatetime,

      onResourcesUpdated,

      resetPage,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
        mdiDatabaseExportOutline,
        mdiSync,
      },
    }
  },
}
</script>
